import { CardRoot, Subtitle } from './styles';
import { Meta } from './Meta';
import { ArticleProps } from '../types';
import { CoverImage, CoverType } from '../../CoverImage';
import { PostStatus, PostSpecies } from '../../../lib/graphql.document';
import { PaidBadge } from '../../PaidBadge';
import { ModerationBadge } from '../../ModerationBadge';

const Feature = ({
  cover,
  title,
  subtitle,
  stats,
  species,
  className,
  isLarge,
  isPaid,
  isImagePrioritized,
  status,
}: ArticleProps): JSX.Element => (
  <CardRoot className={className}>
    <div className="flex flex-col ">
      {cover && (
        <CoverImage
          isPrioritized={isImagePrioritized}
          className="mb-2 block w-full md:mb-3.5"
          cover={cover}
          type={isLarge ? CoverType.Promo : CoverType.Regular}
        />
      )}
      <h2 className="text-lg leading-6 md:leading-6.5">
        <PaidBadge small isPaid={isPaid} />
        <ModerationBadge isOnModeration={status === PostStatus.Moderation} />
        {title} {species === PostSpecies.Blog ? <Subtitle>{subtitle}</Subtitle> : null}
        <Meta commentsCount={stats.commentsCount} isPublished={stats.isPublished} />
      </h2>
    </div>
  </CardRoot>
);

export { Feature };
